import React from "react";
import { Col } from "react-bootstrap";

const TitanPlate = () => {
  return (
    <Col xs={'auto'} style={{ padding: '0 5px' }}>
      <div style={{ border: '1px solid rgba(255, 255, 255, 0.18)', borderRadius: "4px", height: '100%', width: '100%', display: 'flex', alignItems: 'center', backgroundColor: 'rgba(255, 255, 255, 0.02)', userSelect:'none' }} className="clickable">
        <div style={{ padding: "0 10px" }} className="fs-lg">
          Titan 1d
        </div>
      </div>
    </Col>
  );
};

export default TitanPlate;