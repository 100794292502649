import React, { useState, useEffect } from "react";
import './MainPage.css';
import { Col, Row, Badge } from "react-bootstrap";
import SymbolPlate from "./subcomps/SymbolPlate";
import ForecastArea from "./subcomps/ForecastArea";
import Subscription from "../account/views/Subscription";
import { useSubscriptionStatus } from "../../contexts/SubscriptionContext";
import TitanPlate from "./subcomps/TitanPlate";

const MainPage = () => {
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const { subscriptionStatus, trialUsed, loading: subscriptionLoading, refreshSubscriptionStatus } = useSubscriptionStatus();
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);

  useEffect(() => {
    const savedSymbol = localStorage.getItem('selectedSymbol');
    if (savedSymbol) {
      setSelectedSymbol(savedSymbol);
    }
  }, []);

  const saveRecentSymbols = (symbol) => {
    const recentSymbols = JSON.parse(localStorage.getItem('recentSymbols')) || [];
    if (!recentSymbols.includes(symbol)) {
      recentSymbols.push(symbol);
      localStorage.setItem('recentSymbols', JSON.stringify(recentSymbols));
    }
  };

  const handleSymbolChange = (symbol) => {
    setSelectedSymbol(symbol);
    localStorage.setItem('selectedSymbol', symbol);
    saveRecentSymbols(symbol);
  };

  useEffect(() => {
    refreshSubscriptionStatus();
  }, [refreshSubscriptionStatus]);

  return (
    <div className="main-page-container" style={{ position: 'relative' }}>
      <Row>
        <div style={{ display: 'flex', justifyContent: 'center'}}>
          <Row style={{ maxWidth: '600px', width: '100%' }}>
            <TitanPlate />
            <SymbolPlate
              selectedSymbol={selectedSymbol}
              setSelectedSymbol={handleSymbolChange}
              recentSymbols={JSON.parse(localStorage.getItem('recentSymbols')) || []}
            />
          </Row>
        </div>
      </Row>
      <Row className="forecast-area-row">
        <Col style={{ display: 'flex', justifyContent: 'center', margin: 'auto', width: '90%', height: '100%' }} className="forecast-area-container">
          <ForecastArea 
            selectedSymbol={selectedSymbol}
          />
        </Col>
      </Row>
      {subscriptionLoading ? (
        <></>
      ) : (
        !subscriptionStatus && !trialUsed ? (
          <div style={{ fontSize:'1.2em', position: "absolute", top: "90px", left: "50%", transform: "translate(-50%, -50%)", zIndex: 5 }}>
            <Badge pill className="text-gradient-primary clickable border border-primary py-1 px-3" onClick={() => setShowSubscriptionModal(true)}>
              <span className="text-gradient-primary lh-lg">Get Live Forecasts - Free Trial</span>
            </Badge>
          </div>
        ) : (
          !subscriptionStatus && trialUsed && (
            <div style={{ fontSize:'1.2em', position: "absolute", top: "90px", left: "50%", transform: "translate(-50%, -50%)", zIndex: 5 }}>
            <Badge pill className="text-gradient-primary clickable border border-primary py-1 px-3" style={{ backgroundColor:'var(--si-body-bg)' }} onClick={() => setShowSubscriptionModal(true)}>
              <span className="text-gradient-primary lh-lg">Get Live Forecasts</span>
            </Badge>
          </div>
        ))
      )}
      <Subscription show={showSubscriptionModal} onClose={() => setShowSubscriptionModal(false)} />
    </div>
  );
}

export default MainPage;