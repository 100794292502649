import React, { useEffect, useState, useRef } from "react";
import { useFetchManual } from "../../../utils/useFetchManual";
import ForecastChart from "./ForecastChart";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const ForecastArea = ({ selectedSymbol }) => {
  const { fetchData } = useFetchManual();
  // const [streamData, setStreamData] = useState([]);
  const prevSelectedSymbol = useRef();
  const eventSourceRef = useRef(null);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const startEventSource = (latestTimestamp) => {
      // Close any existing SSE connection
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }
  
      // Start SSE connection with the latest timestamp
      eventSourceRef.current = new EventSource(
        `${API_BASE_URL}/mdata/forcasting-chart-stream/${selectedSymbol}/?last_timestamp=${latestTimestamp}`
      );
  
      eventSourceRef.current.onmessage = (event) => {
        const newData = JSON.parse(event.data);
        setChartData(newData);
        // setStreamData((prevData) => [...prevData, newData]);
      };
  
      eventSourceRef.current.onerror = (error) => {
        console.error("EventSource failed:", error);
        eventSourceRef.current.close();
        // Retry connection after 5 seconds
        setTimeout(() => startEventSource(latestTimestamp), 5000);
      };
    };

    if (selectedSymbol && selectedSymbol !== prevSelectedSymbol.current) {
      fetchData(`/mdata/forcasting-chart-data/${selectedSymbol}`).then((response) => {
        if (response && response.length > 0) {
          // Extract the most recent timestamp
          setChartData(response);
          const latestTimestamp = response[response.length - 1].x;
          startEventSource(latestTimestamp);
        }
      });
      prevSelectedSymbol.current = selectedSymbol;
    }
  }, [selectedSymbol, fetchData]);

  

  useEffect(() => {
    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }
    };
  }, []);

  return (
    <>
      {/* <ForecastChart forecastChartData={[...(forecastChartData || []), ...streamData]} selectedSymbol={selectedSymbol} /> */}
      <ForecastChart forecastChartData={chartData} selectedSymbol={selectedSymbol} />
    </>
  );
};

export default ForecastArea;
