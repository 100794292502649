import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useFetch } from '../utils/useFetch';

const SubscriptionContext = createContext();

export const useSubscriptionStatus = () => useContext(SubscriptionContext);

export const SubscriptionProvider = ({ children }) => {
  const { data, error, loading, refetch } = useFetch('/account/check_subscription/');
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [trialUsed, setTrialUsed] = useState(true);

  useEffect(() => {
    if (data) {
      setSubscriptionStatus(data.active_subscription);
      setTrialUsed(data.trial_used);
    }
  }, [data]);

  const refreshSubscriptionStatus = useCallback(() => {
    refetch();
  }, [refetch]);

  return (
    <SubscriptionContext.Provider value={{ subscriptionStatus, trialUsed, error, loading, refreshSubscriptionStatus }}>
      {children}
    </SubscriptionContext.Provider>
  );
};
