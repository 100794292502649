import React, { useState, useEffect, useCallback, useRef } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import "./ForecastChart.css";
import { Row } from "react-bootstrap";
import HighchartsMore from "highcharts/highcharts-more";

HighchartsMore(Highcharts);

const ForecastChart = ({ selectedSymbol, forecastChartData }) => {
  // We no longer store forecastData in React state, just “latest” forecast data.
  // const [latestDataPoint, setLatestDataPoint] = useState(null);
  const [latestForecastData, setLatestForecastData] = useState([]);
  
  // Reference to the chart container div
  const chartContainerRef = useRef(null);
  // Reference to the HighchartsReact component (so we can do chartRef.current.chart)
  const chartRef = useRef(null);

  // Timeout ref for clearing ephemeral hover forecast
  const forecastTimeoutRef = useRef(null);

  /**
   * When the base chart data arrives, find the “latest” data point that has forecasts,
   * and store that forecast set so we can plot it permanently.
   */
  useEffect(() => {
    if (forecastChartData && forecastChartData.length > 0) {
      const latestWithForecasts = forecastChartData
        .slice()
        .reverse()
        .find((item) => item.forecasts && Object.keys(item.forecasts).length > 0);
      if (latestWithForecasts) {
        // setLatestDataPoint(latestWithForecasts);

        const forecastData = [];
        const baseTimestamp = latestWithForecasts.x;
        Object.keys(latestWithForecasts.forecasts).forEach((quantile) => {
          latestWithForecasts.forecasts[quantile].forEach((value, index) => {
            forecastData.push({
              x: baseTimestamp + (index + 1) * 60 * 1000,
              y: value,
              quantile: quantile,
            });
          });
        });
        setLatestForecastData(forecastData);
      }
    }
  }, [forecastChartData]);

  /**
   * Helper: build a line data array for a single quantile
   */
  const buildLine = (allPoints, quantile) => {
    return allPoints.filter((p) => p.quantile === quantile);
  };

  /**
   * Helper: build an arearange from lower/upper quantiles
   */
  const buildArearange = (allPoints, lowerQuantile, upperQuantile) => {
    // Convert pairs (forecast_0.05, forecast_0.95) etc. into arearange objects
    return allPoints
      .filter((p) => p.quantile === lowerQuantile || p.quantile === upperQuantile)
      .map((p) => ({
        x: p.x,
        low: p.quantile === lowerQuantile ? p.y : undefined,
        high: p.quantile === upperQuantile ? p.y : undefined,
      }))
      .reduce((acc, item) => {
        const existing = acc.find((i) => i.x === item.x);
        if (existing) {
          if (item.low !== undefined) existing.low = item.low;
          if (item.high !== undefined) existing.high = item.high;
        } else {
          acc.push(item);
        }
        return acc;
      }, []);
  };

  /**
   * Create a stable chart config object that includes empty “hover forecast” series.
   */
  const createOptions = useCallback(
    (data) => {
      const vwapData = data
        .map((item) => [item.x, item.vwap])
        .filter((item) => item[1] !== undefined);

      return {
        time: { useUTC: false },
        chart: {
          backgroundColor: "var(--si-body-bg)",
          color: "var(--si-body-color)",
          zoomType: null,
          panning: { enabled: true, type: "x" },
          marginRight: 0,
          marginLeft: 0,
          zooming: {
            mouseWheel: { enabled: true },
            pinchType: "x",
          },
        },
        rangeSelector: {
          enabled: false,
          // buttons: [
          //   {
          //     type: "minute",
          //     count: 20,
          //     text: "20m",
          //     title: "View 1 month",
          //   },
          //   {
          //     type: "minute",
          //     count: 60,
          //     text: "60m",
          //     title: "View 3 months",
          //   },
          //   {
          //     type: "all",
          //     text: "All",
          //     title: "View all",
          //   },
          // ],
          // inputEnabled: false,
          // floating: true,
          // labelStyle: {
          //   color: "var(--si-body-color)",
          // },
          // buttonTheme: {
          //   fill: "var(--si-body-bg)",
          //   stroke: "var(--si-border-color)",
          //   'stoke-width': 1,
          //   style: {
          //     color: "var(--si-body-color)",
          //     states: {
          //       hover: {

          //       },
          //       select: {
          //         fill: "var(--si-primary-color)",
          //         style: {
          //           color: "var(--si-body-color)",
          //         },
          //       }
          //     }
          //   }
          // },
        },
        navigator: {
          enabled: true,
          height: 18,
          stickToMax: true,
        },
        scrollbar: { enabled: false },
        credits: { enabled: false },
        exporting: { enabled: false },
        tooltip: {
          shape: "square",
          headerShape: "callout",
          borderWidth: 0,
          shadow: false,
          backgroundColor: "var(--si-body-bg)",
          style: {
            color: "var(--si-body-color)",
          },
          positioner: function (labelWidth, labelHeight, point) {
            const chart = this.chart;
            
            // Pin tooltip to bottom-left of the plot area:
            return {
              x: chart.plotLeft,
              y: chart.plotTop + chart.plotHeight - labelHeight
            };
          }
        },
        stockTools: {
          gui: { enabled: false },
        },
        title: { text: "" },
        yAxis: [
          {
            gridLineColor: "var(--si-border-color-translucent)",
            labels: {
              x: 0,
              style: {
                color: "var(--si-body-color)",
                fontSize: "10px",
              },
            },
          },
          {
            gridLineColor: "var(--si-border-color-translucent)",
            labels: { enabled: false },
            opposite: true,
            linkedTo: 0,
          },
        ],
        xAxis: {
          labels: {
            style: {
              color: "var(--si-body-color)",
            },
          },
          minRange: 20 * 60 * 1000, // 20 minutes
          ordinal: false, // Ensure Highcharts does not "guess" the x-axis points
          events: {
            afterSetExtremes: function (event) {
              // const chart = this.chart;
              const maxX = Math.max(...forecastChartData.map((d) => d.x));
              if (event.max < maxX) {
                this.setExtremes(event.min, maxX, true, false);
              }
            },
          },
        },
        plotOptions: {
          series: {
            cursor: "pointer",
            point: {
              events: {
                mouseOver: function () {
                  if (this.series.type === "candlestick") {
                    if (this.forecasts) {
                      // Build ephemeral forecast for this specific candle
                      const ephemeralForecastData = [];
                      const baseTimestamp = this.x;
                      Object.keys(this.forecasts).forEach((quantile) => {
                        this.forecasts[quantile].forEach((value, index) => {
                          ephemeralForecastData.push({
                            x: baseTimestamp + (index + 1) * 60 * 1000,
                            y: value,
                            quantile: quantile,
                          });
                        });
                      });

                      const chart = this.series.chart;
                      const mid = chart.get("forecast-midpoint");
                      const range50 = chart.get("forecast-range-50");
                      const range90 = chart.get("forecast-range-90");

                      if (mid && range50 && range90) {
                        const midpoint = ephemeralForecastData.filter(
                          (pt) => pt.quantile === "forecast_0.5"
                        );
                        const range50Data = buildArearange(
                          ephemeralForecastData,
                          "forecast_0.25",
                          "forecast_0.75"
                        );
                        const range90Data = buildArearange(
                          ephemeralForecastData,
                          "forecast_0.05",
                          "forecast_0.95"
                        );

                        // Update each ephemeral series data
                        mid.setData(midpoint, false);
                        range50.setData(range50Data, false);
                        range90.setData(range90Data, false);

                        chart.redraw();
                      }

                      // Clear any existing timeout so we don’t remove it too soon
                      if (forecastTimeoutRef.current) {
                        clearTimeout(forecastTimeoutRef.current);
                      }
                    }
                  }
                },
                mouseOut: function () {
                  // Start a 2-second timer to clear the ephemeral forecast
                  if (forecastTimeoutRef.current) {
                    clearTimeout(forecastTimeoutRef.current);
                  }
                  forecastTimeoutRef.current = setTimeout(() => {
                    const chart = this.series ? this.series.chart : null;
                    if (chart) {
                      chart.get("forecast-midpoint")?.setData([], false);
                      chart.get("forecast-range-50")?.setData([], false);
                      chart.get("forecast-range-90")?.setData([], false);
                      chart.redraw();
                    }
                  }, 2000);
                },
              },
            },
            states: {
              inactive: { enabled: false },
            },
            animation: false,
          },
        },
        series: [
          // Candlestick
          {
            type: "candlestick",
            name: selectedSymbol,
            data: data
              .filter((item) => item.open !== undefined)
              .map((item) => ({
                x: item.x,
                open: item.open,
                high: item.high,
                low: item.low,
                close: item.close,
                volume: item.volume,
                forecasts: item.forecasts,
              })),
            color: "#eb1515",
            upColor: "transparent",
            lineColor: "#eb1515",
            upLineColor: "#1a9a49",
            lineWidth: 1,
            dataGrouping: { enabled: false },
            animation: false,
          },
          // VWAP
          {
            type: "line",
            name: "VWAP",
            data: vwapData,
            color: "#0d6efd",
            lineWidth: 1,
            enableMouseTracking: false,
            animation: false,
          },
          // Ephemeral Hover Forecast Series
          {
            id: "forecast-midpoint",
            type: "line",
            name: "Forecast Midpoint (Hover)",
            data: [],
            color: "rgba(134, 178, 206, 0.9)",
            dashStyle: "Dash",
            yAxis: 1,
            enableMouseTracking: false,
            zIndex: 0,
            animation: false,
          },
          {
            id: "forecast-range-50",
            type: "arearange",
            name: "interval 50 (Hover)",
            data: [],
            color: "rgba(134, 178, 206, 0.6)",
            fillOpacity: 0.2,
            yAxis: 1,
            enableMouseTracking: false,
            zIndex: 0,
            animation: false,
          },
          {
            id: "forecast-range-90",
            type: "arearange",
            name: "interval 90 (Hover)",
            data: [],
            color: "rgba(134, 178, 206, 0.4)",
            fillOpacity: 0.1,
            yAxis: 1,
            enableMouseTracking: false,
            zIndex: 0,
            animation: false,
          },
          // Always-on Latest Forecast
          {
            type: "line",
            name: "Latest Forecast Midpoint",
            data: buildLine(latestForecastData, "forecast_0.5"),
            color: "rgba(13, 161, 255, 0.9)",
            dashStyle: "Dash",
            enableMouseTracking: false,
            zIndex: 0,
            animation: false,
            events: {
              afterAnimate: function () {
                // Animated dashed effect
                const chart = this.chart;
                const series = chart.series.find(
                  (s) => s.name === "Latest Forecast Midpoint"
                );
                if (!series || !series.graph) return;
                const graphPath = series.graph.element;
                if (!graphPath) return;

                let offset = 0;
                const animate = () => {
                  offset -= 0.25;
                  graphPath.setAttribute("stroke-dashoffset", offset);
                  requestAnimationFrame(animate);
                };
                requestAnimationFrame(animate);
              },
            },
          },
          {
            type: "arearange",
            name: "Latest interval 50",
            data: buildArearange(latestForecastData, "forecast_0.25", "forecast_0.75"),
            color: "rgba(13, 161, 255, 0.6)",
            fillOpacity: 0.2,
            enableMouseTracking: false,
            zIndex: 0,
            animation: false,
          },
          {
            type: "arearange",
            name: "Latest interval 90",
            data: buildArearange(latestForecastData, "forecast_0.05", "forecast_0.95"),
            color: "rgba(13, 161, 255, 0.4)",
            fillOpacity: 0.1,
            yAxis: 1,
            enableMouseTracking: false,
            zIndex: 0,
            animation: false,
          },
        ],
      };
    },
    [selectedSymbol, latestForecastData, forecastChartData]
  );

  // This holds the chart config
  const [chartOptions, setChartOptions] = useState(null);

  // Build initial chart options whenever forecastChartData changes
  useEffect(() => {
    if (!forecastChartData) return;
    const data = forecastChartData.map((item) => ({
      x: item.x,
      open: item.open,
      high: item.high,
      low: item.low,
      close: item.close,
      volume: item.volume,
      vwap: item.vwap,
      forecasts: item.forecasts,
    }));
    setChartOptions(createOptions(data));
  }, [forecastChartData, createOptions]);

  /**
   * Whenever data updates, try to auto-scroll to the right edge
   */
  useEffect(() => {
    if (!chartRef.current || !chartRef.current.chart) return; // Ensure chart exists
    if (!forecastChartData?.length) return; // Ensure data exists
  
    const chart = chartRef.current.chart;
    const xAxis = chart.xAxis[0];
    if (!xAxis) return; // Ensure xAxis exists
  
    const { min, max, dataMax } = xAxis.getExtremes();
    const visibleRange = max - min;
    const threshold = visibleRange * 0.1;
  
    const newestX = forecastChartData[forecastChartData.length - 1].x;
    const latestForecastX = latestForecastData.length
      ? latestForecastData[latestForecastData.length - 1].x
      : newestX;
  
    const maxX = Math.max(newestX, latestForecastX);
  
    if (dataMax - max < threshold) {
      const shift = maxX - max + 10;
      xAxis.setExtremes(min + shift, max + shift, true, false);
    }
  }, [forecastChartData, latestForecastData]);
  

  return (
    <div className="forecast-chart-container" ref={chartContainerRef}>
      {chartOptions && (
        <Row className="forecast-chart-row">
          <HighchartsReact
            ref={chartRef}
            highcharts={Highcharts}
            constructorType={"stockChart"}
            options={chartOptions}
          />
        </Row>
      )}
    </div>
  );
};

export default ForecastChart;
