import React, { useCallback, useState, useEffect } from "react";
import AsyncSelect from 'react-select/async';
import debounce from 'lodash.debounce';
import { useAuth0 } from '@auth0/auth0-react';
import axios from "axios";
import { Col } from "react-bootstrap";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const SymbolPlate = ({ selectedSymbol, setSelectedSymbol, recentSymbols }) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (selectedSymbol) {
      setSelectedOption({
        value: selectedSymbol,
        label: selectedSymbol, // Adjust this if you have a different label format
      });
    } else {
      setSelectedOption(null);
    }
  }, [selectedSymbol]);

  const handleTickerChange = (selectedOption) => {
    setSelectedSymbol(selectedOption ? selectedOption.label.split(' - ')[0] : null);
    setSelectedOption(selectedOption);
  };

  // Function to load ticker options asynchronously
  const loadTickerOptions = async (inputValue, callback) => {
    if (!isAuthenticated) {
      callback([]);
      return;
    }
    if (!inputValue) {
      const options = recentSymbols.map(symbol => ({
        value: symbol,
        label: symbol, // Adjust this if you have a different label format
      }));
      callback(options.length ? options : [{ value: '', label: 'No recent symbols' }]);
      return;
    }
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: 'https://api.tckrai.com',
        },
      });

      const response = await axios.get(`${API_BASE_URL}/mdata/tickers/`, {
        params: { q: inputValue },
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      const options = response.data.map(ticker => ({
        value: ticker.id,
        label: `${ticker.symbol} - ${ticker.company_name}`,
      }));
      callback(options);
    } catch (error) {
      console.error('Error fetching tickers:', error);
      callback([]);
    }
  };

  // Create a debounced version of loadTickerOptions
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedLoadTickerOptions = useCallback(
      debounce((inputValue, callback) => {
        if (!inputValue) {
          callback([]);
          return;
        }
        loadTickerOptions(inputValue, callback);
      }, 300),
      [] // Dependencies array; empty to ensure it's created only once
    );

  return (
    <Col style={{ padding: '0 5px' }}>
      <AsyncSelect
        cacheOptions
        loadOptions={debouncedLoadTickerOptions}
        defaultOptions={recentSymbols
          .filter(symbol => symbol !== selectedSymbol) // Exclude selectedSymbol
          .map(symbol => ({
            value: symbol,
            label: symbol, // Adjust this if you have a different label format
          })) || [{ value: '', label: 'No recent symbols' }]}
        onChange={handleTickerChange}
        value={selectedOption}
        placeholder="Select a Ticker"
        // isClearable
        styles={{
          clearIndicator: (provided, state) => ({
            ...provided,
            color: state.isFocused ? 'rgba(255, 255, 255, 0.7)' : 'rgba(255, 255, 255, 0.5)', // Adjust color based on focus
            cursor: 'pointer',
            padding: '8px',
            '&:hover': {
              color: 'rgba(255, 255, 255, 0.9)', // Darken on hover
            },
          }),
          dropdownIndicator: (provided, state) => ({
            ...provided,
            color: state.isFocused ? 'rgba(255, 255, 255, 0.7)' : 'rgba(255, 255, 255, 0.5)', // Adjust color based on focus
            cursor: 'pointer',
            padding: '8px',
            '&:hover': {
              color: 'rgba(255, 255, 255, 0.9)', // Darken on hover
            },
          }),
        
          indicatorSeparator: (provided, state) => ({
            ...provided,
            backgroundColor: 'rgba(255, 255, 255, 0.2)', // Subtle separator color
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? 'rgba(255, 255, 255, 0.1)' : 'transparent', // Highlight color on hover
            color: state.isSelected ? 'rgba(255, 255, 255, 0.7)' : 'rgba(255, 255, 255, 0.7)', // Text color when selected
            cursor: 'pointer',
            ':active': {
              backgroundColor: !state.isDisabled
                ? state.isSelected
                  ? 'rgba(255, 255, 255, 0.2)'
                  : 'transparent'
                : undefined,
            },
          }),
          input: (provided, state) => ({
            ...provided,
            color: '',
          }),
          singleValue: (provided, state) => ({
            ...provided,
            color: 'rgba(255, 255, 255, 0.7)',
          }),
          control: (provided, state) => ({
            ...provided,
            borderColor: state.isFocused ? 'rgba(255, 255, 255, 0.28)' : 'rgba(255, 255, 255, 0.18)',
            boxShadow: state.isFocused ? '0 0 0 1px rgba(255, 255, 255, 0.36)' : 'none', // Override boxShadow to remove blue tint
            '&:hover': {
              borderColor: state.isFocused ? 'rgba(255, 255, 255, 0.28)' : 'rgba(255, 255, 255, 0.18)', // Maintain border color on hover
            },
            backgroundColor: 'rgba(255, 255, 255, 0.02)',
            color: 'rgba(255, 255, 255, 0.7)',
            padding: '0.4rem .5rem',
          }),
          menu: (provided, state) => ({
            ...provided,
            backgroundColor: 'var(--si-modal-bg)',
            border: '1px solid rgba(255, 255, 255, 0.18)',
            zIndex: 100,
          }),
          menuList: (provided, state) => ({
            ...provided,
            backgroundColor: 'var(--si-body-bg)', // Set your desired background color
            zIndex: 200,
          }),
        }}
      />
    </Col>
        
  );
}

export default SymbolPlate;